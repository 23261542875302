.footer {
  width: 100vw;
  height: 40rem;
  background-image: url(../../assets/header\ img.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* margin-top: 5%; */
}

.footer_logo_box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.footer_logo_box div {
  width: 23.5rem;
  position: relative;
}

.footer_logo_box p {
  position: absolute;
  font-size: xx-small;
  color: #fff;
  left: 100%;
  top: 64%;
}

.footer_logo {
  width: 25rem;
  margin-top: 2rem;
}

.footer_main {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}

.footer_navigation {
  display: flex;
  flex-direction: column;

  justify-content: space-evenly;
  align-self: stretch;
  align-items: center;
  flex-basis: 33.33%;
}

.footer_link {
  display: block;
  color: #fff;
  cursor: pointer;
}

.sirpi_logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-basis: 33.33%;
}

.sirpi_logo_txt {
  color: #fff;
}

.footer_address_box {
  display: flex;
  align-items: center;
  color: #fff;
}

.footer_address {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer_text_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_text {
  display: block;
  width: 50rem;
  color: #fff;
  text-align: center;
}

.footer_policy {
  color: #bbb;
  text-decoration: underline;
}
