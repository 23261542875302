.home {
  width: 100%;
  height: auto;
}

.rowOne {
  width: auto;
  height: 50px;
  background-color: #35539f;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rowOne p {
  font-size: 1.5rem;
  color: white;
  font-weight: 600;
  padding-left: 5px;
  padding-right: 30px;
}

.rowTwo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.rowTow_logo {
  width: 23.5rem;
  height: 5.2rem;
  display: flex;
  /* margin-left: 20px; */
  /* position: relative; */
}

.rowTow_logo p {
  font-size: x-small;
  /* left: 82%;
    top: 65%;
    position: absolute; */
  align-self: flex-end;
  margin-bottom: 6px;
}

.logo {
  width: 200px;
}

.rowTwoOptions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rowTwoOptions p {
  padding-left: 5px;
  padding-right: 50px;
  cursor: pointer;
  width: fit-content;
}

.rowTwoOptions p::selection {
  background-color: transparent;
}
.iconFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rowTwoOptions .mailIcon {
  background-color: #ff8730;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 500;
  color: white;
  padding-top: 5px;
}

.rowThree {
  width: 100%;
}

.rowThree img {
  width: 100%;
}

.rowFour {
  position: relative;
  z-index: 1000;
  margin: 0px auto;
  margin-top: 80px;
  margin-bottom: 8%;
  border-radius: 20px;
  width: 70%;
  min-width: auto;
  /* height: 560px; */
  border: 1px solid #ff8730;
  background-color: rgb(255, 255, 255);
  box-shadow: 10px 15px 40px rgba(0, 0, 0, 0.15);
  padding: 5px;
}

.homePara {
  padding: 5% 10%;
  text-align: center;
}

.rowFour form {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 5% auto;
}

.myForm label {
  font-weight: 700;

}

.myForm input {
  border: none;
  border: 1px solid #4366c1;
  padding: 5px 15px;
  font-size: 2rem;
  border-radius: 10px;
  max-width: 60rem;
  width: 95%;
  /* min-width: 22.5rem; */
  margin-bottom: 17px;
  margin-top: 5px;
  outline: none;
  transition: all 0.3s;
}

.myForm input:focus {
  border: 1px solid #ff8730;
}

.dType_box {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: start;
  /* max-width: 50rem; */
  width: 100%;

  /* min-width: 10rem; */
  padding: 2px;
}

/* .dType_box__sdt {
    margin-right: 10px;
}

.dType_box__sf {
    margin-left: 10px;
} */

.dType {
  width: 90%;
  max-width: 60rem;
  font-size: 1.7rem;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 10px;
  border: none;
  background-color: #35539f;
  color: white;
  padding: 10px 17px;
  border-radius: 20px;
  outline: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  appearance: none;
  background-image: url("../../assets/icons/downward-arrow.png");
  background-repeat: no-repeat;
  background-position: right 2rem center;
  background-size: 1em;
}

.submit {
  cursor: pointer;
  border: none;
  background-color: #ff8730;
  color: white;
  font-size: 1.7rem;
  font-weight: 600;
  padding: 15px 20px;
  border-radius: 20px;
  width: auto;
  margin: 60px auto 50px auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit:disabled {
  background-color: rgb(162, 162, 162);
  cursor: not-allowed;
}

.errorUrl {
  color: red;
  font-size: 1.7rem;
  margin-left: 5px;
}
@media (max-width: 1100px) {
  .dType_box {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 5; */
  }
}
@media (max-width: 720px) {
  .submit {
    margin: 25px auto 40px auto;
    padding: 10px 15px;
  }
  .rowTow_logo {
    margin: 0 auto;
  }
  .rowTwoOptions {
    margin: 0 auto;
    width: auto;
  }
  .rowTwo {
    flex-direction: column;
  }

  .rowFour {
    width: auto;
    margin: 11px;
  }
  .mailIcon {
    margin-bottom: 6px;
  }
}
@media (max-width: 480px) {
  .rowTwoOptions p {
    padding-right: 10px;
    padding-left: 9px;
  }
  .mailIcon {
    width: 3rem !important;
    height: 3rem !important;
  }
  .myForm label {
    width: 25rem;
  }
}
