.about {
  width: 100%;
  height: auto;
  /* min-width: 1000px; */
}

.rowOne {
  width: 100vw;
  height: 50px;
  background-color: #35539f;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rowOne p {
  font-size: 1.5rem;
  color: white;
  font-weight: 600;
  padding-left: 5px;
  padding-right: 30px;
}

.rowTwo {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  position: relative;
}

.rowTow_logo {
  width: 23.5rem;
  height: 5.2rem;
  /* margin-left: 20px; */
  position: relative;
}

.rowTow_logo p {
  font-size: xx-small;
  left: 82%;
  top: 65%;
  position: absolute;
}

.logo {
  width: 200px;
}

.rowTwoOptions {
  display: flex;
  align-items: center;
}

.rowTwoOptions p {
  padding-left: 5px;
  padding-right: 50px;
  cursor: pointer;
  width: fit-content;
  margin-right: 5px;
}

.rowTwoOptions p::selection {
  background-color: transparent;
}

.rowTwoOptions div {
  background-color: #ff8730;
  margin-bottom: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 500;
  color: white;
}

.rowThree {
  width: 100vw;
}

.rowThree img {
  width: 100%;
}

.main {
  background: linear-gradient(
    173.28deg,
    rgba(75, 126, 255, 0.2) 5.76%,
    #ffffff 30.82%,
    rgba(255, 255, 255, 0.6248) 68.52%,
    rgba(255, 207, 35, 0.7) 99.41%,
    rgba(255, 135, 48, 0.3) 99.41%
  );
  margin-top: -6px;

  width: 100vw;
}

.abtus {
  position: relative;
}

.main_heading {
  text-align: center;
}

.main_heading__h1 {
  color: #35539f;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  padding: 40px 0 40px 0;
  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.main_heading_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main_heading_box__content {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.main_heading_box__content__heading {
  padding: 60px 0px 40px 0px;
}

.main_heading_box__content__text {
  font-size: 16px;
}

.main_heading_box__img {
  padding: 100px 0px 200px 0px;
}

.main_middle_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}

.main_middle_box_heading {
  padding-bottom: 60px;
}

.main_middle_box_heading__h1 {
  color: #252131;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  padding: 50px 0 30px 0;

  text-transform: uppercase;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.main_middle_box_img {
  height: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  gap: 5px;
}
.main_middle_box_video {
  width: 48rem;
  height: 28rem;

  margin-left: 50px;
}

/* .main_middle_box1 {
   border: 2px solid royalblue;
    
}  */

.main_middle_box2 {
  display: inline-block;
  margin-left: 30px;
}

.main_middle_box_img1 {
  width: 60%;
  margin-left: 100px;
}

.main_middle_box_img2 {
  width: 85%;
}

.main_faqs {
  padding-bottom: 200px;
}

.main_middle_box_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 20px 0px;
}

.details_content {
}

details {
  padding: 0 30px;
  margin: 0 50px;
  border: 1px solid gray;
  margin-bottom: 20px;
}

hr {
  margin: 0px -30px;
}

details > summary {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  font-size: 20px;
  cursor: pointer;
  list-style: none;
}

details > summary::after {
  content: url(../../assets/icons/Vector.png);
  width: 16px;
  height: 16px;
  display: inline-flex;
  transition: transform 0.3s ease;
}

.vector1 {
  /* content: url(../../assets/Vector.svg);
    width: 22%;
    display: inline-flex;
    position: absolute;
    right: 0px;
    mix-blend-mode: overlay;
    overflow: hidden; */
}

.vector2 {
  content: url(../../assets/Vector2.svg);
  width: 22%;
  display: inline-flex;
  position: absolute;
  right: 0px;
  mix-blend-mode: overlay;
  overflow: hidden;
  /* transform: rotate(36.45deg); */
}

details[open] > summary::after {
  transform: rotate(180deg);
  margin-top: 10px;
}

details > summary::-webkit-details-maker {
  display: none;
}

.details_content {
  font-size: 16px;
  padding: 0px 40px;
}

.details_content > ul {
  padding: 20px 0 40px 0;
}

.details_content > ul > li {
  margin: 5px 0;
}
.lgs_box {
  padding-bottom: 300px;
}
.main_middle_box_heading__faq {
  color: #252131;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  margin: 50px 0 30px 0;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.lgs_button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lgs_button__home {
  background-color: #4b7eff;
  color: #fff;
  padding: 8px 30px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
@media (max-width: 1000px) {
  .rowTwo {
    flex-direction: column;
    gap: 8px;
  }
  .main_heading {
    height: 12rem;
  }
  .main_heading_box__img > img {
    width: 80%;
    margin-left: 32px;
  }
  .main_middle_box_heading__h1 {
    font-weight: 500;
    font-size: 20px;
    padding: 50px 0 30px 0;
  }
  .main_heading_box__img {
    padding: 0px;
  }
  .main_middle_box_img {
    flex-direction: column;
  }
  .main_middle_box_img2 {
    width: 78%;
    margin: 30px 0px 30px 35px;
  }
  .main_middle_box_video {
    width: 28rem;
    height: 17rem;
    margin-left: 10px;
  }
  .main_heading_box__content__heading {
    font-size: medium;
  }
  .main_middle_box_heading__faq {
    line-height: 1;
    font-weight: 500;
    font-size: 50px;
    margin: 50px 0 30px 90px;
  }
  .main_middle_box {
    padding-bottom: 20px;
  }
  .detailsPicture {
    width: 100%;
  }
  .main_heading__h1 {
    font-weight: 700;
    font-size: 50px;
    padding: 40px 0 30px 0;
  }
  .main_heading_box__content__heading {
    padding: 20px 0px 20px 0px;
  }
  .lgs_box {
    padding-bottom: 100px;
  }
  .main_faqs {
    padding-bottom: 30px;
  }
}
