.login {
  width: 100%;
  height: auto;
}

.login_logobox {
  position: relative;
  height: 15vh;
  width: auto;
}

.login_logobox::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.login_logobox1 {
  position: absolute;
  width: 23.5rem;
  height: 10rem;
  margin-left: 20px;
}

.logo {
  top: 18%;
  left: 4.5%;
  width: 23.5rem;
  position: absolute;
  -webkit-filter: drop-shadow(-1px 3px 2px #a1a1a1);
  filter: drop-shadow(-1px 3px 2px #a1a1a1);
}

.login_logobox1 p {
  top: 60%;
  left: 100%;
  position: absolute;
  font-size: xx-small;
}

.main {
  max-width: 100vw;
  display: flex;
}
.main_imgbox {
  width: auto;
  display: flex;
  justify-content: center;
}

.main_img {
  object-fit: cover;
  max-width: 70%;
  margin-left: 13.3rem;
}

.main_text {
  font-size: 2rem;
  font-weight: 500;
  margin-left: 8rem;
  margin-top: -3.5rem;
  text-align: center;
}

.login_form {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin-left: 15%;
  line-height: 2;
}

.login_form__text {
  font-size: 2rem;
  font-weight: 500;
}

.login_form_link {
  color: #f28c28;
}

.login_text {
  /* display: flex;
    justify-content: center;
    align-items: center; */
  margin-left: 12rem;
  color: blue;
  font-size: 2.3rem;
  font-weight: 500;
  text-decoration: underline;
}

/* .input_field {
} */

.label {
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 0.7rem;
  margin-bottom: 0.3rem;
  margin-top: 8px;
}

.input_container {
  width: 32rem;
  height: 4rem;
  border: 1px solid #777;
  border-radius: 1.5rem;
  padding: 1rem;
  font-size: 1.5rem;
}

.text2 {
  font-size: 1.2rem;
  /* text-align: right; */
  margin-top: 0.7rem;
  margin-left: 13rem;
}

.password_link {
  color: blue;
}

.login_btn {
  width: 17rem;
 
  height: 4rem;
  color: #fff;
  padding: 1rem;
  text-align: center;
  border-radius: 1.5rem;
border: none;
  background-color: #f28c28;
  font-size: 1.8rem;
  font-weight: 400;
  margin-left: 8rem;
  margin-top: 3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.login_btn:disabled {
  background-color: rgb(179, 179, 179);
}

.footer {
  text-align: center;
  margin-top: 2rem;
}

.footer_text {
  display: block;
  font-size: 1.6rem;
  font-weight: 600;
}

.footer_img {
  width: 13rem;
  margin-top: 0.5rem;
}

@media (max-width: 900px) {
  .main {
    flex-direction: column;
  }
  .login_form {
    margin-left: 0;
    width: auto;
  }
  .logindiv {
    margin: auto;
  }
  .main_img {
    margin-left: 8rem;
  }
}
