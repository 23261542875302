*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

:root {
  --toastify-toast-width: 400px !important;
  --toastify-font-family: monospace !important;
}


html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.3;
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}