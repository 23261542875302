.container {
    width: 100%;
    min-height: 800px;
    background-image: url("/public/maintenance/background.png");
    background-size: 100%;
    background-repeat: no-repeat;
}

.rowOne {
    padding: 10px 3%;
}

.rowOne img {
    width: 180px;
}

.rowTwo {
    padding-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #232323;
}

.rowTwo img {
    width: 350px;
}

.rowTwo h3 {
    font-size: 3rem;
}

.rowTwo h5 {
    font-size: 1.4rem;
    font-weight: 400;
}